
@font-face {
	font-family: 'FoundersGrotesk-Light';
	src:local('FoundersGrotesk-Light'), url(./assets/fonts/founders-grotesk-web-light.eot);
	src:local('FoundersGrotesk-Light'), url(./assets/fonts//founders-grotesk-web-light.eot?) format("embedded-opentype"),
	url(./assets/fonts/founders-grotesk-web-light.woff2) format('woff2'),
	url(./assets/fonts/founders-grotesk-web-light.woff) format('woff');
	font-style: normal;
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: 'FoundersGrotesk-Medium';
	src:local('FoundersGrotesk-Medium'), url(./assets/fonts/founders-grotesk-web-medium.eot);
	src:local('FoundersGrotesk-Medium'), url(./assets/fonts/founders-grotesk-web-medium.eot?) format("embedded-opentype"),
	url(./assets/fonts/founders-grotesk-web-medium.woff2) format("woff2"),
	url(./assets/fonts/founders-grotesk-web-medium.woff) format("woff");
	font-style: normal;
	font-weight: 500;
	font-display: swap;
}
