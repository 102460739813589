#link-panel {
  z-index: 10;
  width: 200%;
  margin-left: -50%;
  padding: 10px 0 15px calc(50% - 15px);
  background: rgba(255, 255, 255, 0.8);
  transition-duration: 0.5s;
}

.shadow-link-panel {
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, .2);
}
