.underline {
  text-decoration: underline;
}

.brand-1 {
  height: 25px
}

.brand-2 {
  height: 75px
}

.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.main-body {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
